@import "../vars_smart";

#error-page {
  h1 {
    border: none;
    font-size: 200px;
    line-height: 200px;
    margin-top: 13%;
    font-weight: bold;
    font-family: 'Impact', 'sans-serif';
    color: $acte2i-color;
  }
  
  .interdit {
    font-size: 35px;
    font-weight: bold;
  }
  
  .refus {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  a {
    font-size: 22px;
    color: $acte2i-color;
    
    &:hover {
      text-decoration: underline;
    }
  }
}
